var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-group-title-wrapper cr-draggable-list cr-draggable-group d-flex align-center py-1",attrs:{"data-type":"group","data-gi":_vm.group.id,"data-title":_vm.group.title,"data-idx":_vm.idx}},[(!_vm.dragGroup)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.SET_CLOSED_GROUP({ id: _vm.group.id, closed: _vm.expandedGroup })}}},[_c('div',[_c('v-icon',{attrs:{"color":_vm.groupColor}},[_vm._v(" "+_vm._s(("mdi-chevron-" + (_vm.expandedGroup ? "down" : "right")))+" ")])],1)]):_vm._e(),(!_vm.editable)?_c('div',{staticClass:"cr-group-title cr-tooltip",on:{"click":function($event){$event.stopPropagation();return _vm.changeEditable.apply(null, arguments)},"mouseover":function (event) { return _vm.SET_TOOLTIP({
          msg: '클릭하여 편집하기',
          position: 'top',
          event: event
        }); },"mouseout":_vm.HIDE_TOOLTIP}},[_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm.group.title))])])]):_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('v-text-field',{ref:"textRef",staticClass:"ml-1",attrs:{"outlined":"","dense":"","hide-details":"","color":_vm.groupColor,"value":_vm.group.title},on:{"blur":_vm.inputBlur,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateTitle.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$refs.textRef.blur()}]}}),_c('v-menu',{attrs:{"offset-x":"","nudge-top":"50","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cr-tooltip",attrs:{"icon":""},on:{"click":function($event){_vm.preventBlur = true},"mouseover":function (event) { return _vm.SET_TOOLTIP({
                msg: '그룹 색상 변경',
                position: 'top',
                event: event
              }); },"mouseout":_vm.HIDE_TOOLTIP}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.groupColor}},[_vm._v("mdi-brush-variant")])],1)]}}]),model:{value:(_vm.showColorPicker),callback:function ($$v) {_vm.showColorPicker=$$v},expression:"showColorPicker"}},[_c('v-color-picker',{attrs:{"width":"330","swatches-max-height":"200","hide-canvas":"","hide-mode-switch":"","hide-sliders":"","hide-inputs":"","show-swatches":""},model:{value:(_vm.groupColor),callback:function ($$v) {_vm.groupColor=$$v},expression:"groupColor"}})],1),_c('v-btn',{staticClass:"cr-tooltip",attrs:{"icon":""},on:{"click":_vm.deleteGroupF,"mouseover":function (event) { return _vm.SET_TOOLTIP({
            msg: '그룹 삭제',
            position: 'top',
            event: event
          }); },"mouseout":_vm.HIDE_TOOLTIP}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1),(_vm.dragGroup)?_c('div',{staticClass:"cr-draggable-group-guard"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }