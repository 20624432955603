<template>
  <div class="d-flex cr-todo-combined-status">
    <div
      v-for="label in labels"
      :key="label.value"
      :style="
        `width: ${label.width * label.cnt}%; background-color: ${label.color}`
      "
    >
      <div
        class="cr-todo-combined-status cr-tooltip"
        @mouseover="
          event =>
            SET_TOOLTIP({
              msg: `${label.title}  ${label.cnt}/${label.totalCnt}  ${(
                (Math.floor(label.cnt) / label.totalCnt) *
                100
              ).toFixed(1)}%`,
              position: 'top',
              event
            })
        "
        @mouseout="HIDE_TOOLTIP"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-combined-status {
  height: 100%;
  width: 100%;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoItem", ["items"]),
    labels() {
      const { value: headerValue, labels } = this.header;
      const items = this.items[this.group.id];
      if (!items) return [];

      const totalCnt = items.length;
      const width = 100 / (totalCnt || 1);
      const obj = {};
      let defaultLabel = {};
      labels.forEach(l => {
        if (l.defaultLabel) defaultLabel = { ...l, cnt: 0, width, totalCnt };
        else obj[l.value] = { ...l, cnt: 0, width, totalCnt };
      });

      items.forEach(item => {
        if (item[headerValue] && obj[item[headerValue]]) {
          obj[item[headerValue]].cnt += 1;
        } else {
          defaultLabel.cnt += 1;
        }
      });

      return [...Object.values(obj), defaultLabel];
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"])
  }
};
</script>
