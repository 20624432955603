<template>
  <tr
    class="cr-draggable-list cr-draggable-row"
    data-id="additional"
    :data-gi="group.id"
  >
    <td
      v-for="header in headers"
      :key="header.value"
      :style="`width: ${header.width}px; min-width: ${header.width}px;`"
      :class="`cr-resize-td cr-${header.value} cr-${header.type}`"
    >
      <component
        v-if="
          header.type === headerUtils.TITLE().type || header.type === 'SELECT'
        "
        key="addTr"
        :is="$props.components[header.type]"
        :header="header"
        v-bind="$props"
        v-on="$listeners"
      />
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.cr-resize-td.cr-SELECT {
  opacity: 0.7;
}
</style>

<script>
import SELECT from "./type/Select.vue";
import headerUtils from "@/todo/store/utils/header";

export default {
  components: { SELECT },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Array,
      default: () => []
    },
    components: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      headerUtils
    };
  }
};
</script>
