<template>
  <div
    class="cr-group-title-wrapper cr-draggable-list cr-draggable-group d-flex align-center py-1"
    data-type="group"
    :data-gi="group.id"
    :data-title="group.title"
    :data-idx="idx"
  >
    <v-btn
      v-if="!dragGroup"
      icon
      @click="SET_CLOSED_GROUP({ id: group.id, closed: expandedGroup })"
    >
      <div>
        <v-icon :color="groupColor">
          {{ `mdi-chevron-${expandedGroup ? "down" : "right"}` }}
        </v-icon>
      </div>
    </v-btn>
    <div
      v-if="!editable"
      class="cr-group-title cr-tooltip"
      @click.stop="changeEditable"
      @mouseover="
        event =>
          SET_TOOLTIP({
            msg: '클릭하여 편집하기',
            position: 'top',
            event
          })
      "
      @mouseout="HIDE_TOOLTIP"
    >
      <div class="text-truncate">
        <span>{{ group.title }}</span>
      </div>
    </div>

    <div v-else class="d-flex align-center flex-grow-1">
      <v-text-field
        ref="textRef"
        outlined
        dense
        hide-details
        :color="groupColor"
        class="ml-1"
        :value="group.title"
        @blur="inputBlur"
        @keydown.enter="updateTitle"
        @keydown.esc="$refs.textRef.blur()"
      />
      <v-menu offset-x v-model="showColorPicker" nudge-top="50" nudge-right="5">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="cr-tooltip"
            @click="preventBlur = true"
            @mouseover="
              event =>
                SET_TOOLTIP({
                  msg: '그룹 색상 변경',
                  position: 'top',
                  event
                })
            "
            @mouseout="HIDE_TOOLTIP"
          >
            <v-icon :color="groupColor">mdi-brush-variant</v-icon>
          </v-btn>
        </template>
        <v-color-picker
          width="330"
          swatches-max-height="200"
          hide-canvas
          hide-mode-switch
          hide-sliders
          hide-inputs
          show-swatches
          v-model="groupColor"
        />
      </v-menu>

      <v-btn
        icon
        class="cr-tooltip"
        @click="deleteGroupF"
        @mouseover="
          event =>
            SET_TOOLTIP({
              msg: '그룹 삭제',
              position: 'top',
              event
            })
        "
        @mouseout="HIDE_TOOLTIP"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>

    <div v-if="dragGroup" class="cr-draggable-group-guard"></div>
  </div>
</template>

<style lang="scss" scoped>
.cr-group-title-wrapper {
  position: sticky;
  height: 48px;
  top: 0px;
  left: 0px;
  z-index: 3;
  background: #fff;
  padding-left: 7px;
  .cr-group-title {
    &:hover {
      border: thin solid var(--group-color);
      margin-left: 4px;
    }
    max-width: 700px;
    margin-left: 5px;
    cursor: text;

    .text-truncate {
      padding: 7px 11px;
      span {
        color: var(--group-color);
        font-weight: bold;
      }
    }
  }

  .v-text-field ::v-deep {
    max-width: 700px;
    fieldset {
      border-color: var(--group-color) !important;
    }

    input {
      color: var(--group-color);
      font-weight: bold;
    }
  }

  &.cr-drop-cursor {
    position: relative;
    height: 56px;
    top: 0px;
    padding: 8px 0px !important;
  }

  &.cr-drag-target {
    .cr-draggable-group-guard {
      background: rgba(116, 183, 246, 0.12) !important;
    }
  }

  // 개발자도구 열려있으면 커서안바뀜
  &.cr-draggable-list {
    cursor: grab;
    &.cr-drop-cursor {
      cursor: grabbing !important;
      * {
        cursor: grabbing !important;
      }
    }
  }

  .cr-draggable-group-guard {
    position: absolute;
    top: 4px;
    left: 0px;
    right: 0px;
    bottom: 4px;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    idx: {
      type: Number,
      default: 0
    },
    group: {
      type: Object,
      default: () => ({})
    },
    dragGroup: {
      type: Boolean
    },
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$parent.$el.style.setProperty("--group-color", `${this.groupColor}`);
    });
  },
  data() {
    return {
      preventBlur: false,
      editable: false,
      showColorPicker: false
    };
  },
  computed: {
    ...mapGetters("todoGroup", ["closedGroup"]),
    ...mapGetters("todoItem", ["items"]),
    expandedGroup() {
      return !this.closedGroup[this.group.id];
    },
    groupItems() {
      return this.items[this.group.id] ?? [];
    },
    groupColor: {
      get() {
        return this.group.color;
      },
      set(color) {
        this.updateColor(color);
      }
    }
  },
  watch: {
    scrollTop() {
      this.editable = false;
      this.showColorPicker = false;
    },
    showColorPicker(showColorPicker) {
      if (!showColorPicker) this.preventBlur = false;
      if (this.editable && !showColorPicker) {
        this.$refs.textRef.focus();
      }
    }
  },
  methods: {
    ...mapMutations("todoGroup", ["SET_CLOSED_GROUP"]),
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("todoGroup", [
      "updateGroupTitle",
      "updateGroupColor",
      "deleteGroup"
    ]),
    inputBlur() {
      setTimeout(() => {
        if (this.preventBlur) return;
        this.editable = false;
        this.showColorPicker = false;
      }, 200);
    },
    changeEditable() {
      this.HIDE_TOOLTIP();
      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    updateTitle(e) {
      const title = e.target.value.trim();
      const { id: groupId, boardId, title: prevTitle } = this.group;
      if (isBlank(title) || prevTitle == title) {
        this.$refs.textRef.blur();
        return;
      }

      this.editable = false;
      this.updateGroupTitle({ groupId, boardId, title, prevTitle });
    },
    updateColor(color) {
      const { id: groupId, boardId, color: prevColor } = this.group;
      this.editable = false;
      this.showColorPicker = false;
      this.updateGroupColor({ groupId, boardId, color, prevColor });
      this.$parent.$el.style.setProperty("--group-color", `${color}`);
    },
    deleteGroupF() {
      const { id: groupId, boardId } = this.group;
      this.confirm({
        message:
          "해당 그룹을 삭제하시겠습니까? <br/>삭제 후 복구할 수 없습니다.",
        callback: () => {
          this.deleteGroup({ groupId, boardId });
        },
        showCloseBtn: true
      });
    }
  }
};
</script>
