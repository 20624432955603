<template>
  <div id="todoList" :class="cls" @scroll="scroll">
    <Group
      ref="groupRef"
      v-for="(group, idx) in groups"
      :key="group.id"
      :idx="idx"
      :group="group"
      :scrollTop="scrollTop"
      :renderElCnt="renderElCnt"
      :screenHeight="screenHeight"
      :TABLE_TD_HEIGHT="TABLE_TD_HEIGHT"
      :dragGroup.sync="dragGroup"
    />

    <!-- 필터링 결과 없을 때 -->
    <div
      v-if="filterNoContent"
      class="d-flex align-center justify-center"
      style="height: 100%;"
    >
      결과가 없습니다.
    </div>

    <!-- 컬럼(헤더) 사이즈 조절 선 -->
    <div
      style="display: none;"
      class="cr-col-resize cr-full-height-line cr-drag-prevent"
    />
    <Menu />
  </div>
</template>

<style lang="scss">
.cr-col-resize {
  position: absolute;
  user-select: none;
  top: 0px;
  bottom: 0px;
  right: -1px;
  width: 6px;
  cursor: col-resize;

  &.cr-full-height-line {
    z-index: 3;
    border-right: 4px solid var(--v-primary-base);
  }
}
</style>
<style lang="scss" scoped>
.cr-todo-list::v-deep {
  position: relative;
  // 66px -> 툴바, 필터 탭
  height: calc(100% - 66px);
  overflow: scroll;
  padding-bottom: 120px;

  &.cr-drag-group {
    cursor: grabbing !important;
  }
  &.cr-detail {
    overflow: hidden;
    height: 100%;
    padding-bottom: 0px;
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import Group from "./group";
import Menu from "./group/Menu.vue";

// const TABLE_TH_HEIGHT = 48;
const TABLE_TD_HEIGHT = 38;
// const TABLE_TD_HEIGHT = 48;
export default {
  components: { Group, Menu },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.$nextTick(() => {
      this.$parent.$el.style.setProperty("--i-t-h", `${TABLE_TD_HEIGHT}px`);
      setTimeout(this.resize, 0);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  data() {
    return {
      TABLE_TD_HEIGHT,
      // 그룹 리스트 화면 높이
      screenHeight: 0,
      // 렌더링 가능한 엘리먼트 개수
      renderElCnt: 0,
      // 스크롤 위치
      scrollTop: 0,
      scrollLeft: 0,
      // 그룹 드래그 여부
      dragGroup: false
    };
  },
  computed: {
    ...mapGetters("todoGroup", ["groups"]),
    ...mapGetters("todoFilter", ["filtersIds"]),
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("todoMenu", { showMenu: "show", menuType: "type" }),
    ...mapGetters("todoDetail", { showDetail: "show" }),
    filterNoContent() {
      return this.groups.length == 0 && this.filtersIds.isFilter;
    },
    cls() {
      let cls = "cr-todo-list";
      if (this.dragGroup) cls += " cr-drag-group";
      if (this.showDetail) cls += " cr-detail";
      return cls;
    }
  },
  watch: {
    routeBoardId() {
      // 보드 이동시 스크롤 초기화
      if (!this.$el) return;
      this.$el.scrollTop = 0;
    }
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    scroll(e) {
      const { scrollTop, scrollLeft } = e.target;
      this.scrollTop = scrollTop;
      if (this.menuType === "header" && this.scrollLeft != scrollLeft) {
        this.CLOSE_MENU();
      }
      this.scrollLeft = scrollLeft;
    },
    resize() {
      // 화면크기 변할때 마다 렌더링 가능한 엘리먼트 개수 계산 (virtual list)
      setTimeout(() => {
        const { offsetHeight } = this.$el;
        this.screenHeight = offsetHeight;
        this.renderElCnt = Math.ceil(offsetHeight / TABLE_TD_HEIGHT);
      }, 0);
    }
  }
};
</script>
