<template>
  <v-hover v-slot="{ hover }">
    <div class="cr-combine-timeline">
      <span
        class="cr-timeline text-truncate"
        :style="`background: ${linearGradient}`"
      >
        {{ itemValue(hover) }}
      </span>
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-combine-timeline::v-deep {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  .cr-timeline {
    font-size: 12px;
    border-radius: 14px;
    background: #e0e0e0;
    padding: 5px 13px;
    width: 100%;
    text-align: center;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { getMonthAndDate } from "@/commons/utils/moment";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoItem", ["items"]),
    combinedRange() {
      const { value: headerValue } = this.header;
      let start = 0;
      let end = 0;
      this.items[this.group.id]?.forEach(item => {
        if (item[headerValue]) {
          const [first, second] = item[headerValue]?.split(",") ?? [];
          let sd = new Date(first).getTime();
          let ed = new Date(second).getTime();
          if (sd > ed) {
            sd = ed;
            ed = new Date(first).getTime();
          }

          if (!start || sd < start) {
            start = sd;
          }

          if (!end || ed > end) {
            end = ed;
          }
        }
      });

      return [start, end];
    },
    percent() {
      const [start, end] = this.combinedRange;
      if (!start || !end) return 0;

      const d1 = (end - start) / (1000 * 60 * 60 * 24) + 1;
      const d2 = (new Date().getTime() - start) / (1000 * 60 * 60 * 24) + 1;
      return (Math.floor(d2) / d1) * 100;
    },
    linearGradient() {
      return `linear-gradient(to right, #a7c4ff ${this.percent}%, #e0e0e0 ${this.percent}%)`;
    },
    itemValue() {
      return hover => {
        const [st, ed] = this.combinedRange;
        if (!st || !ed) return "-";

        if (hover) {
          //   return `${this.percent.toFixed(2)}%`;
          return (ed - st) / (1000 * 60 * 60 * 24) + 1 + "d";
        }

        if (st == ed) return getMonthAndDate(st);
        return `${getMonthAndDate(st)} - ${getMonthAndDate(ed)}`;
      };
    }
  }
};
</script>
