var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex cr-todo-combined-status"},_vm._l((_vm.labels),function(label){return _c('div',{key:label.value,style:(("width: " + (label.width * label.cnt) + "%; background-color: " + (label.color)))},[_c('div',{staticClass:"cr-todo-combined-status cr-tooltip",on:{"mouseover":function (event) { return _vm.SET_TOOLTIP({
            msg: ((label.title) + "  " + (label.cnt) + "/" + (label.totalCnt) + "  " + ((
              (Math.floor(label.cnt) / label.totalCnt) *
              100
            ).toFixed(1)) + "%"),
            position: 'top',
            event: event
          }); },"mouseout":_vm.HIDE_TOOLTIP}})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }