<template>
  <tr
    :class="trCls"
    data-type="row"
    :data-gi="group.id"
    :data-id="item.id"
    :data-pi="item.parentId"
    :data-sc="item.subCnt"
  >
    <td v-for="header in headers" :key="header.value" :class="tdCls(header)">
      <component
        :is="$props.components[header.type]"
        :header="header"
        v-bind="$props"
        v-on="$listeners"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Array,
      default: () => []
    },
    scrollTop: {
      type: Number,
      default: 0
    },
    TABLE_TD_HEIGHT: {
      type: Number,
      default: 48
    },
    dragItemId: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    },
    components: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    trCls() {
      let cls = "cr-draggable-list cr-draggable-row";
      if (this.dragItemId == this.item.id) {
        cls += " cr-drop-cursor cr-drag-target";
      }
      return cls;
    },
    tdCls() {
      let cls = "";
      if (this.item.parentId > 0) cls += "cr-td-subItem";
      if (this.item.isLast) cls += " cr-last-sub";
      return ({ value, type }) => cls + ` cr-${value} cr-${type}`;
    }
  }
};
</script>
