<template>
  <tr class="cr-todo-combine-tr">
    <td
      v-for="header in headers"
      :key="header.value"
      :class="`cr-${header.value} cr-${header.type}`"
    >
      <component
        :is="$props.components[header.type]"
        :header="header"
        v-bind="$props"
        v-on="$listeners"
      />
    </td>
  </tr>
</template>

<style lang="scss" scoped>
.cr-todo-combine-tr {
  td.cr-SELECT {
    border: none !important;
  }
  td.cr-TITLE {
    border-left: none !important;
    border-bottom: none !important;
  }
}
</style>

<script>
import TIMELINE from "./combine/Timeline.vue";
import NUMBER from "./combine/Number.vue";
import STATUS from "./combine/Status.vue";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Array,
      default: () => []
    },
    components: {
      type: Object,
      default: () => ({
        TIMELINE,
        NUMBER,
        STATUS
      })
    }
  }
};
</script>
