<template>
  <thead>
    <tr
      data-id="thead"
      :data-gi="group.id"
      class="cr-draggable-list cr-draggable-row"
    >
      <th
        v-for="(header, idx) in headers"
        scope="col"
        data-type="col"
        :key="header.value"
        :data-id="header.value"
        :class="thCls(header, idx)"
        @mouseover="mouseover"
        @mouseout="mouseout"
      >
        <v-hover v-slot="{ hover }">
          <component
            :is="$props.components[header.type] || $props.components.Title"
            :header="header"
            :hover="hover"
            v-bind="$props"
            v-on="$listeners"
            @mousedown="mousedown"
          />
        </v-hover>
      </th>
    </tr>
  </thead>
</template>

<style lang="scss" scoped>
.v-data-table.cr-todo-table {
  > .v-data-table__wrapper {
    table {
      > thead > tr {
        position: sticky;
        top: 48px; // 그룹 타이틀 크기
        z-index: 2;

        th {
          cursor: default;
          position: relative;
          border-top: thin solid rgba(0, 0, 0, 0.12);

          &.cr-drag-target div {
            background: rgba(116, 183, 246, 0.12) !important;
          }

          &.cr-drop-target {
            border: 2px dashed var(--v-primary-base);
            .cr-header-title-wrapper {
              padding-left: 4px !important;
            }
          }

          // 타임라인 숫자 연결
          &.cr-connected {
            border-right-color: transparent;
          }

          &:hover::v-deep {
            border-right-color: transparent;
            .cr-col-resize {
              border-right: 4px solid rgba(0, 0, 0, 0.18);
            }

            &.cr-drag-target {
              border-right-color: rgba(0, 0, 0, 0.12);

              .cr-col-resize {
                border-right: none;
              }
            }

            &.cr-drop-target {
              border-right-color: var(--v-primary-base);

              .cr-col-resize {
                border-right: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";
import ADDITIONAL from "./type/Additional.vue";
import SELECT from "./type/Select.vue";
import Title from "./type/Title.vue";

const obj = {};
export default {
  props: {
    startIndex: {
      type: Number,
      default: 0
    },
    scroll_top: {
      type: Number,
      default: 0
    },
    group: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Array,
      default: () => []
    },
    groupItems: {
      type: Array,
      default: () => []
    },
    dataTableModel: {
      type: Array,
      default: () => []
    },
    components: {
      type: Object,
      default: () => ({
        SELECT,
        ADDITIONAL,
        Title
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      const todoList = document.getElementById("todoList");
      if (!todoList) return;

      obj["todoList"] = todoList;
      obj["resizeLine"] = todoList.querySelector(".cr-full-height-line");
      // 첫번째 그룹에서 이벤트 바인딩 해준다.
      const index = this.groups.findIndex(g => g.id == this.group.id);
      if (index !== 0 || todoList.mousemove || todoList.mouseup) return;

      todoList.addEventListener("mousemove", this.mousemove);
      todoList.addEventListener("mouseup", this.mouseup);
    });
  },
  data() {
    return { interval: null };
  },
  computed: {
    ...mapGetters("todoItem", ["items"]),
    ...mapGetters("todoGroup", ["groups"]),
    ...mapGetters("dragDrop", ["dragMode"]),
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("todoMenu", { menuType: "type" }),
    thCls: ({ headers }) => (header, idx) => {
      const { value, type, connectedColumn } = header;
      let cls = `cr-${value} cr-${type}`;
      // DataTable 체크박스, 타이틀, 추가버튼은 DnD 지원 X
      if (
        type !== "SELECT" &&
        type !== headerUtils.TITLE().type &&
        type !== headerUtils.ADDITIONAL().type
      ) {
        cls += " cr-draggable-list cr-draggable-col";
      }

      // 타임라인 컬럼 숫자연결 처리
      if (
        type === headerUtils.TIMELINE().type &&
        value === headers[idx + 1].connectedColumn
      ) {
        cls += " cr-connected";
      }

      // 숫자컬럼 타임라인 연결처리
      if (
        type == headerUtils.NUMBER().type &&
        connectedColumn &&
        ((connectedColumn === headers[idx - 1]?.value &&
          connectedColumn === headers[idx + 1]?.connectedColumn) ||
          (connectedColumn === headers[idx + 1]?.connectedColumn &&
            connectedColumn === headers[idx + 2]?.value) ||
          connectedColumn === headers[idx + 1]?.value)
      ) {
        cls += " cr-connected";
      }

      return cls;
    }
  },
  watch: {
    scroll_top() {
      if (this.menuType !== "header") return;
      this.CLOSE_MENU();
    }
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    // th 가로 스크롤 이동
    mouseover(e) {
      if (!this.dragMode || this.interval !== null) return;

      const isTitle = !!e.target.closest(".cr-TITLE");
      let moveRight = false;
      if (!isTitle) {
        const th = e.target.closest("th");
        const [{ right }] = obj["todoList"].getClientRects();
        const [{ right: thRight }] = th.getClientRects();
        moveRight = right - 100 < thRight;
      }

      this.interval = setInterval(() => {
        if (isTitle) obj["todoList"].scrollLeft -= 10;
        else if (moveRight) obj["todoList"].scrollLeft += 10;
      }, 10);
    },
    mouseout() {
      if (this.interval == null) return;

      clearInterval(this.interval);
      this.interval = null;
    },

    // resize
    mousedown(e) {
      this.CLOSE_MENU();
      obj["pageX"] = e.pageX;
      obj["target"] = e.target;
      obj["targetTh"] = e.target.closest("th");
      obj["targetThW"] = obj["targetTh"].offsetWidth;
    },
    mousemove(e) {
      const { todoList, resizeLine, target, targetTh, targetThW, pageX } = obj;
      if (!targetTh) return;

      const { id: value } = targetTh.dataset;
      const [header] = this.headers.filter(h => h.value == value);
      if (!header) return;

      const width = targetThW + e.pageX - pageX;
      const minWidth = headerUtils[header.type]().minWidth;
      obj["resizeWidth"] = width < minWidth ? minWidth : width;

      // 컬럼 DnD시 사이즈 변경되는 문제로 addTr td에 style 적용
      todoList.querySelectorAll(".cr-todo-table table").forEach(t => {
        const td = t.querySelector(`td.cr-resize-td.cr-${value}`);
        const wrapper = t.querySelector(
          `th.cr-${value} .cr-header-title-wrapper`
        );

        td.style.width = obj["resizeWidth"] + "px";
        td.style.minWidth = obj["resizeWidth"] + "px";
        wrapper.style.width = obj["resizeWidth"] + "px";
      });

      // 컬럼 사이즈 조절선 이동
      // v-data-table-wrapper
      const { parentElement: tableWrapper } = this.$el.parentElement;
      const { right: tableWrapperRight } = tableWrapper.getBoundingClientRect();
      const { right: targetRight } = target.getBoundingClientRect();

      Object.assign(resizeLine.style, {
        display: "",
        height: todoList.scrollHeight + "px",
        right: tableWrapperRight - targetRight + "px"
      });
    },
    mouseup() {
      const { resizeLine, targetTh, resizeWidth } = obj;
      if (!targetTh) return;

      obj.targetTh = null;
      resizeLine.style.display = "none";
      this.updateHeader({
        mutation: "RESIZE_HEADER",
        boardId: this.routeBoardId,
        params: {
          value: targetTh.dataset.id,
          width: resizeWidth
        }
      });
    }
  }
};
</script>
