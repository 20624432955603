<template>
  <v-data-table
    class="cr-todo-table"
    show-select
    hide-default-header
    hide-default-footer
    :headers="headers"
    :items="groupItems"
    :mobile-breakpoint="0"
    :items-per-page="1000"
    v-model="dataTableModel"
    v-drag-and-drop="{
      DRAG_COMP: 'list',
      genHelper,
      dragstart,
      genDropzone,
      markDropzone,
      mouseup
    }"
  >
    <template v-slot:top="{}">
      <GroupTitle v-bind="$props" draggable="true" :dragGroup="dragGroup" />
    </template>

    <template v-slot:header="{ props: { headers } }">
      <GroupHeader
        v-bind="$props"
        draggable="true"
        :headers="headers"
        :startIndex="startIndex"
        :scroll_top="scroll_top"
        :groupItems="groupItems"
        :dataTableModel.sync="dataTableModel"
      />
    </template>

    <template v-slot:body="{ items, headers }">
      <GroupBody
        v-bind="$props"
        draggable="true"
        :items="items"
        :headers="headers"
        :beforeElHeight="beforeElHeight"
        :totalHeight="totalHeight"
        :dragItemId="dragItemId"
        :dataTableModel.sync="dataTableModel"
        :TABLE_TD_HEIGHT="TABLE_TD_HEIGHT"
      />
    </template>
  </v-data-table>
</template>

<style lang="scss" scoped>
.v-data-table.cr-todo-table::v-deep {
  display: contents;
  > .v-data-table__wrapper {
    overflow: unset;

    > table {
      > tbody > tr > td {
        height: var(--i-t-h);
      }

      > thead > tr,
      > tbody > tr {
        th,
        td {
          background: #fff;
          border-right: thin solid rgba(0, 0, 0, 0.12);
          padding: 0px;
          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
          &:first-child {
            border-left: thin solid rgba(0, 0, 0, 0.12);
          }

          &.cr-SELECT {
            min-width: 48px;
            max-width: 48px;
            width: 48px;
            position: sticky !important;
            left: 0px;
            z-index: 1;
            border-left: 4px solid var(--group-color);
            &.cr-td-subItem {
              border-left: none;
              border-bottom: none;
              padding-left: 4px;
              &.cr-last-sub {
                border-bottom: thin solid rgba(0, 0, 0, 0.12);
              }
            }
          }
          &.cr-TITLE {
            position: sticky !important;
            left: 48px;
            z-index: 1;
            max-width: 1px;
            background: rgba(255, 254, 254, 0.9);
          }
          &.cr-CREATED_LOG,
          &.cr-STATUS,
          &.cr-PERSON,
          &.cr-TEXT,
          &.cr-NUMBER,
          &.cr-TIMELINE,
          &.cr-CHECK,
          &.cr-LINK {
            max-width: 1px;
          }
        }

        &.cr-drag-target {
          background: transparent !important;
          td {
            background: rgba(116, 183, 246, 0.12) !important;
          }
        }
        &.cr-tr-ghost {
          td:first-child {
            border: 2px dashed var(--v-primary-base);
            border-right: none;
          }
          td:last-child {
            border: 2px dashed var(--v-primary-base);
            border-left: none;
          }

          &.cr-tr-ghost-sub {
            td:first-child {
              border: none;
              border-top: thin solid rgba(0, 0, 0, 0.12);
              border-bottom: thin solid rgba(0, 0, 0, 0.12);
            }
            td:last-child {
              border: 2px dashed var(--v-primary-base);
            }
          }
        }
      }

      th,
      td,
      tr {
        // 개발자도구 열려있으면 커서안바뀜
        &.cr-draggable-list {
          cursor: grab;
          &.cr-drop-cursor {
            cursor: grabbing !important;
            * {
              cursor: grabbing !important;
            }
          }
        }

        &.cr-resize-td {
          cursor: default;
        }
      }
    }
  }

  .cr-group-ghost {
    border: 2px dashed var(--v-primary-base);
    height: 36px;
    cursor: grabbing !important;
  }

  .cr-drag-prevent {
    cursor: default !important;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import GroupTitle from "./title";
import GroupHeader from "./header";
import GroupBody from "./body";

export default {
  components: { GroupTitle, GroupHeader, GroupBody },
  props: {
    idx: {
      type: Number,
      default: 0
    },
    group: {
      type: Object,
      default: () => ({})
    },
    TABLE_TD_HEIGHT: {
      type: Number,
      default: 48
    },
    screenHeight: {
      type: Number,
      default: 0
    },
    renderElCnt: {
      type: Number,
      default: 0
    },
    scrollTop: {
      type: Number,
      default: 0
    },
    dragGroup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scroll_top: 0,
      dragItemId: ""
    };
  },
  computed: {
    ...mapGetters("todoModal", { modalType: "type" }),
    ...mapGetters("dragDrop", ["dragComp"]),
    ...mapGetters("todoGroup", ["groups", "closedGroup"]),
    ...mapGetters("todoHeader", ["header"]),
    ...mapGetters("todoItem", [
      "moveItemId",
      "items",
      "subItems",
      "showSubItems",
      "selectedItems"
    ]),
    headers: {
      get() {
        return [{ type: "SELECT", value: "data-table-select" }, ...this.header];
      },
      set(headers) {
        this.SET_HEADER(headers);
      }
    },
    groupItems: {
      get() {
        const { id: groupId } = this.group;
        if (
          !this.renderElCnt ||
          !this.items[groupId] ||
          this.closedGroup[groupId] ||
          (this.idx > 0 &&
            this.prevGroupHeight - this.scrollTop > this.screenHeight)
        ) {
          return [];
        }

        const items = [];
        this.items[groupId].forEach(t => {
          items.push(t);
          if (!this.showSubItems[t.id]) return;

          const subItems = this.subItems[t.id];
          subItems.forEach((st, idx) =>
            items.push({ ...st, isLast: idx === subItems.length - 1 })
          );
        });

        return items.slice(this.startIndex, this.renderElCnt + this.startIndex);
      },
      set() {}
    },
    dataTableModel: {
      get() {
        return this.selectedItems[this.group.id];
      },
      set(selectedItems) {
        const { id: groupId } = this.group;
        this.SET_SELECTED_ITEMS({ groupId, selectedItems });
      }
    },
    prevGroupHeight() {
      if (this.idx == 0) return 0;

      let prevGroupHeight = 0;
      for (let i = this.idx - 1; i >= 0; i -= 1) {
        let prevLength = this.getTotalCnt(this.groups[i].id);
        if (this.closedGroup[this.groups[i].id]) prevLength = 0;

        // + 3은 추가 row, 종합 row, 여백 row
        prevGroupHeight += (prevLength + 3) * this.TABLE_TD_HEIGHT;
        // 그룹 이름, 헤더
        prevGroupHeight += 96;
      }
      return prevGroupHeight;
    },
    startIndex() {
      const totalCnt = this.getTotalCnt(this.group.id);
      let startIndex = Math.floor(this.scroll_top / this.TABLE_TD_HEIGHT);
      if (startIndex > totalCnt) startIndex = totalCnt;
      return startIndex;
    },
    beforeElHeight() {
      const height = this.startIndex * this.TABLE_TD_HEIGHT;
      return height > this.totalHeight ? 0 : height;
    },
    totalHeight() {
      if (this.closedGroup[this.group.id]) return 0;
      // + 3은 추가 row, 종합 row, 여백 row
      return (this.getTotalCnt(this.group.id) + 3) * this.TABLE_TD_HEIGHT;
    }
  },
  watch: {
    dataTableModel(dataTableModel) {
      if (!dataTableModel || dataTableModel?.length == 0) return;
      if (this.modalType === "selcted") return;

      const params = { bottom: 100, left: "calc(50% - 372px)" };
      this.SET_MODAL({ type: "selected", params });
    },
    scrollTop(scrollTop) {
      if (this.idx > 0) {
        if (this.prevGroupHeight >= scrollTop) {
          this.scroll_top = 0;
          return;
        }

        const s = scrollTop - this.prevGroupHeight;
        this.scroll_top = s < 0 ? 0 : s;
        return;
      }

      this.scroll_top = scrollTop;
    }
  },
  methods: {
    ...mapMutations("todoItem", ["SET_SELECTED_ITEMS"]),
    ...mapMutations("todoModal", ["SET_MODAL"]),
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapMutations("todoHeader", ["SET_HEADER"]),
    ...mapMutations("todoTooltip", ["HIDE_TOOLTIP"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    ...mapActions("todoGroup", ["moveGroup"]),
    ...mapActions("todoItem", ["moveItem"]),
    getTotalCnt(groupId) {
      let cnt = 0;
      this.items[groupId]?.forEach(i => {
        cnt += 1;
        if (this.showSubItems[i.id]) cnt += this.subItems[i.id].length;
      });

      return cnt;
    },

    // drag & drop
    genHelper(e, setDragInfo) {
      if (this.moveItemId) return;
      const { target } = e;
      if (!target || target.closest(".cr-drag-prevent")) return null;

      const { nodeName, type: inputType } = e.target;
      if (nodeName == "INPUT" && inputType == "text") return null;

      const dragTarget = target.closest(".cr-draggable-list");
      if (!dragTarget) return null;

      let title = "";
      const clsList = ["cr-drop-cursor", "cr-draggable-list"];
      const dragInfo = { ...dragTarget.dataset, dragTarget, idx: this.idx };
      switch (dragTarget.dataset.type) {
        case "group": {
          // 옮겨지는 영역 고스트
          dragInfo["ghost"] = document.createElement("div");
          dragInfo.ghost.classList.add(...[...clsList, "cr-group-ghost"]);

          title = dragTarget.dataset.title;
          break;
        }
        case "row": {
          dragInfo["ghost"] = document.createElement("tr");
          dragInfo.ghost.classList.add(...[...clsList, "cr-tr-ghost"]);
          dragInfo.ghost.innerHTML = `<td></td><td colspan="${this.headers.length}"></td>`;

          title = dragTarget.querySelector("td.cr-TITLE")?.innerText;
          break;
        }
        case "col": {
          title = dragTarget.querySelector(".cr-header-title")?.innerText;
          break;
        }
        default:
          return null;
      }

      // 마우스 따라다니는 고스트
      const ghost = document.createElement("div");
      ghost.setAttribute("data-type", dragTarget.dataset.type);
      ghost.classList.add("dnd-ghost");
      ghost.innerHTML = `
      <div class="dnd-node">
        <div class="dnd-text"><span>${title}</span></div>
      </div>
      `;

      setDragInfo(dragInfo);
      return ghost;
    },
    dragstart(e, setDragInfo, { type, id, dragTarget }) {
      this.CLOSE_MENU();
      this.HIDE_TOOLTIP();
      document.body.classList.add("no-drop");
      dragTarget.classList.add("cr-drag-target");
      this.$emit("update:dragGroup", type === "group");

      if (type !== "row") return;
      // 아이템의 경우 헤더나 그룹과 달리 가상스크롤 하면서 드래그한 아이템이 사라지기 때문에 따로 전달해준다.
      this.dragItemId = id;
      this.dataTableModel = [];
    },
    genDropzone({ type, idx }) {
      // 선택된 group에서 한번만 실행 (idx 비교)
      if (this.dragComp !== "list" || idx !== this.idx) return;
      // 그룹 DnD시 아이템 가리기
      if (type === "group") {
        this.$el.parentNode
          .querySelectorAll(".v-data-table__wrapper")
          .forEach(el => (el.style.display = "none"));
      }

      (type === "col" ? this.$el : this.$el.parentNode)
        .querySelectorAll(`.cr-draggable-list.cr-draggable-${type}`)
        .forEach(el => el.classList.add("cr-drop-cursor"));
    },
    markDropzone(e, dragInfo) {
      if (this.dragComp !== "list") return;
      const { type, id, ghost, sc, dragTarget } = dragInfo;
      const target = e.target.closest(".cr-drop-cursor");
      const setInfo = (target, position, ghostParent, isSub) => {
        dragInfo["dropTarget"] = target;
        dragInfo["position"] = position;
        dragInfo["isSub"] = !!isSub;
        ghostParent[position](ghost);
      };

      switch (type) {
        case "group": {
          if (target == ghost) return;
          if (!target || target.classList.contains("cr-drag-target")) {
            ghost?.parentNode?.removeChild(ghost);
            dragInfo["position"] = "";
            dragInfo["dropTarget"] = null;
            return;
          }

          const dragIdx = dragTarget.dataset.idx;
          const targetIdx = target.dataset.idx;
          if (e.offsetY <= 40 && dragIdx - targetIdx !== -1) {
            setInfo(target, "before", target);
          } else if (dragIdx - targetIdx !== 1) {
            setInfo(target, "after", target);
          }
          return;
        }
        case "col": {
          this.$el
            .querySelectorAll(".cr-drop-target")
            .forEach(n => n.classList.remove("cr-drop-target"));

          if (!target || target.dataset.id == id) return;
          return target.classList.add("cr-drop-target");
        }
        case "row": {
          if (!target || target == ghost) return;
          if (target.classList.contains("cr-drag-target")) {
            const { pi: prevPi } = target.previousSibling?.dataset || {};
            const { pi: nextPi } = target.nextSibling?.dataset || {};
            // 하위아이템이 없고 바로 위에 하위아이템 목록일때
            if (e.offsetY <= 40 && !parseInt(sc) && !!parseInt(prevPi)) {
              if (parseInt(dragInfo.pi) === parseInt(prevPi)) return;
              ghost.classList.add("cr-tr-ghost-sub");
              setInfo(target.previousSibling, "after", target.previousSibling);
              return;
            } else if (parseInt(dragInfo.pi) && !parseInt(nextPi)) {
              if (target.nextSibling.classList.contains("cr-tr-ghost")) return;
              // 드래그한 아이템이 하위아이템이고 다음 아이템이 상위레벨일때
              ghost.classList.remove("cr-tr-ghost-sub");
              setInfo(target.nextSibling, "before", target.nextSibling);
              return;
            }

            ghost?.parentNode?.removeChild(ghost);
            dragInfo["position"] = "";
            return;
          }

          // 내 하위일때
          if (id == target.dataset?.pi) return;
          // 하위아이템이 있을때 하위아이템 목록 DnD방지
          if (!!parseInt(sc) && target.dataset?.pi > 0) return;
          // 타겟아이템이 하위아이템일때
          if (target.dataset?.pi > 0) {
            ghost.classList.add("cr-tr-ghost-sub");
          } else {
            ghost.classList.remove("cr-tr-ghost-sub");
          }

          if (target.dataset?.id === "thead") {
            const tbody = e.target.closest("table").querySelector("tbody");
            // drag한 아이템이 첫번째가 아닐때
            if (!tbody.children[1]?.classList?.contains("cr-drag-target")) {
              setInfo(target, "after", tbody.children[0]);
            }
            return;
          }

          if (target.dataset?.id === "additional") {
            if (
              !target?.previousSibling?.classList?.contains("cr-drag-target")
            ) {
              setInfo(target, "before", target);
            }
            return;
          }

          if (
            e.offsetY <= 40 &&
            !target.previousSibling?.classList?.contains("cr-drag-target")
          ) {
            setInfo(target, "before", target);
          } else if (
            !target.nextSibling?.classList?.contains("cr-drag-target")
          ) {
            if (this.showSubItems[target?.dataset?.id]) {
              if (parseInt(sc)) return;

              ghost.classList.add("cr-tr-ghost-sub");
              setInfo(target, "after", target, true);
              return;
            }
            setInfo(target, "after", target);
          }

          return;
        }
        default:
          break;
      }
    },
    mouseup(e, { type, id, gi, pi, dropTarget, position, isSub }) {
      const { boardId } = this.group;
      switch (type) {
        case "group": {
          this.$emit("update:dragGroup", false);
          const ghost = document.querySelector(".cr-group-ghost");
          ghost?.parentNode?.removeChild(ghost);
          this.$el.parentNode
            .querySelectorAll(".v-data-table__wrapper")
            .forEach(el => (el.style.display = ""));

          const target = e.target.closest(".cr-drop-cursor");
          if (!target || !dropTarget) break;

          const { gi: targetGroupId } = dropTarget.dataset;
          this.moveGroup({ groupId: gi, boardId, targetGroupId, position });
          break;
        }
        case "col": {
          const target = e.target.closest(".cr-drop-target");
          if (!target) break;

          this.updateHeader({
            mutation: "MOVE_HEADER",
            boardId,
            params: { value1: id, value2: target.dataset.id }
          });
          break;
        }
        case "row": {
          const target = e.target.closest(".cr-drop-cursor");
          const ghost = document.querySelector(".cr-tr-ghost");
          ghost?.parentNode?.removeChild(ghost);
          if (target && dropTarget && position) {
            let { gi: gi2, id: id2, pi: pi2 } = dropTarget.dataset;
            if (!id2 || !gi2) break;

            pi2 = isSub ? id2 : pi2;
            this.dragItemId = "";
            this.moveItem({ boardId, gi, id, pi, gi2, id2, pi2, position });
          }
          break;
        }

        default:
          break;
      }

      const clsList = ["cr-drag-target", "cr-drop-cursor", "cr-drop-target"];
      (type == "col" ? this.$el : this.$el.parentNode)
        .querySelectorAll(".cr-drag-target, .cr-drop-cursor, cr-drop-target")
        .forEach(el => el.classList.remove(...clsList));
      document.body.classList.remove("no-drop");
    }
  }
};
</script>
