<template>
  <div
    class="cr-combined-number px-2 text-truncate cr-tooltip"
    :class="isConnected ? 'cr-connected-timeline' : ''"
    :key="header.combinedFormat"
    @click="changeCombinedFormat"
    @mouseover="
      event => SET_TOOLTIP({ msg: tooltipTxt, position: 'top', event })
    "
    @mouseout="HIDE_TOOLTIP"
  >
    {{ combinedNumber }}
  </div>
</template>

<style lang="scss" scoped>
.cr-combined-number {
  height: 100%;
  line-height: 47px;
}

.cr-connected-timeline {
  cursor: pointer;
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoItem", ["items"]),
    ...mapGetters("todoRoute", ["routeBoardId"]),
    isConnected() {
      return !!this.header.connectedColumn;
    },
    tooltipTxt() {
      if (this.header.format == "percent") {
        if (this.header.combinedFormat == "row") return "타임라인 종합 진척률";
        return "열 평균";
      }

      if (this.header.combinedFormat == "row") return "타임라인 종합 기간";
      return "열 합";
    },
    combinedRange() {
      const { connectedColumn } = this.header;
      let availableCnt = 0;
      let sumPeriod = 0;
      let averagePercent = 0;
      let start = 0;
      let end = 0;
      this.items[this.group.id]?.forEach(item => {
        if (item[connectedColumn]) {
          const [first, second] = item[connectedColumn]?.split(",") ?? [];
          let sd = new Date(first).getTime();
          let ed = new Date(second).getTime();
          if (sd > ed) {
            sd = ed;
            ed = new Date(first).getTime();
          }

          if (sd && ed) {
            availableCnt += 1;
            sumPeriod += (ed - sd) / (1000 * 60 * 60 * 24) + 1;

            const d1 = (ed - sd) / (1000 * 60 * 60 * 24) + 1;
            const d2 = (new Date().getTime() - sd) / (1000 * 60 * 60 * 24) + 1;
            let percent = parseFloat(((Math.floor(d2) / d1) * 100).toFixed(1));
            percent = percent > 100 ? 100 : percent;
            percent = d2 < 0 ? 0 : percent;
            averagePercent += percent;
          }

          if (!start || sd < start) {
            start = sd;
          }

          if (!end || ed > end) {
            end = ed;
          }
        }
      });

      return {
        start,
        end,
        sumPeriod,
        averagePercent: parseFloat((averagePercent / availableCnt).toFixed(1))
      };
    },
    combinedTimeline() {
      // 열 합
      const { start, end, sumPeriod } = this.combinedRange;
      if (this.header.combinedFormat == "row") {
        return (end - start) / (1000 * 60 * 60 * 24) + 1 + "d";
      }
      return sumPeriod + "d";
    },
    combinedTimelinePercent() {
      const { start, end, averagePercent } = this.combinedRange;
      if (!start || !end) return 0 + "%";
      if (this.header.combinedFormat == "row") {
        const d1 = (end - start) / (1000 * 60 * 60 * 24) + 1;
        const d2 = (new Date().getTime() - start) / (1000 * 60 * 60 * 24) + 1;
        let percent = parseFloat(((Math.floor(d2) / d1) * 100).toFixed(1));
        percent = percent > 100 ? 100 : percent;
        percent = d2 < 0 ? 0 : percent;
        return percent + "%";
      }

      return averagePercent + "%";
    },
    combinedNumber() {
      const { value: headerValue, connectedColumn, format } = this.header;
      if (connectedColumn) {
        if (format == "percent") return this.combinedTimelinePercent;
        else return this.combinedTimeline;
      }

      let number = 0;
      this.items[this.group.id]?.forEach(item => {
        if (item[headerValue] && parseInt(item[headerValue])) {
          number += parseInt(item[headerValue]);
        }
      });

      return number || "";
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    changeCombinedFormat() {
      if (!this.isConnected) return;
      const { combinedFormat } = this.header;
      this.updateHeader({
        mutation: "CHANGE_HEADER",
        boardId: this.routeBoardId,
        params: {
          header: {
            combinedFormat: combinedFormat == "row" ? "column" : "row"
          },
          value: this.header.value
        }
      });
    }
  }
};
</script>
