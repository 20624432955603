var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
    DRAG_COMP: 'list',
    genHelper: _vm.genHelper,
    dragstart: _vm.dragstart,
    genDropzone: _vm.genDropzone,
    markDropzone: _vm.markDropzone,
    mouseup: _vm.mouseup
  }),expression:"{\n    DRAG_COMP: 'list',\n    genHelper,\n    dragstart,\n    genDropzone,\n    markDropzone,\n    mouseup\n  }"}],staticClass:"cr-todo-table",attrs:{"show-select":"","hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.groupItems,"mobile-breakpoint":0,"items-per-page":1000},scopedSlots:_vm._u([{key:"top",fn:function(ref){return [_c('GroupTitle',_vm._b({attrs:{"draggable":"true","dragGroup":_vm.dragGroup}},'GroupTitle',_vm.$props,false))]}},{key:"header",fn:function(ref){
  var headers = ref.props.headers;
return [_c('GroupHeader',_vm._b({attrs:{"draggable":"true","headers":headers,"startIndex":_vm.startIndex,"scroll_top":_vm.scroll_top,"groupItems":_vm.groupItems,"dataTableModel":_vm.dataTableModel},on:{"update:dataTableModel":function($event){_vm.dataTableModel=$event},"update:data-table-model":function($event){_vm.dataTableModel=$event}}},'GroupHeader',_vm.$props,false))]}},{key:"body",fn:function(ref){
  var items = ref.items;
  var headers = ref.headers;
return [_c('GroupBody',_vm._b({attrs:{"draggable":"true","items":items,"headers":headers,"beforeElHeight":_vm.beforeElHeight,"totalHeight":_vm.totalHeight,"dragItemId":_vm.dragItemId,"dataTableModel":_vm.dataTableModel,"TABLE_TD_HEIGHT":_vm.TABLE_TD_HEIGHT},on:{"update:dataTableModel":function($event){_vm.dataTableModel=$event},"update:data-table-model":function($event){_vm.dataTableModel=$event}}},'GroupBody',_vm.$props,false))]}}]),model:{value:(_vm.dataTableModel),callback:function ($$v) {_vm.dataTableModel=$$v},expression:"dataTableModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }