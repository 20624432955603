<template>
  <v-checkbox
    hide-details
    color="gray"
    class="mt-0 pt-0 cr-modal-close-prevent"
    style="padding-left: 9px;"
    :value="checkboxValue"
    :indeterminate="indeterminate"
    @click="checkboxClick"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    dataTableModel: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("todoItem", ["items", "subItems"]),
    groupId() {
      return this.group.id;
    },
    groupItems() {
      return this.items[this.groupId] || [];
    },
    itemCnt() {
      const groupLength = this.groupItems.length;
      const subCnt = this.groupItems.reduce((p, i) => p + i.subCnt, 0);
      return groupLength + subCnt;
    },
    indeterminate() {
      // 선택된 아이템 개수가 0 보다 크고 전체 아이템 개수와 다를때
      const { length } = this.dataTableModel;
      return length > 0 && this.itemCnt !== length;
    },
    checkboxValue() {
      const { length } = this.dataTableModel;
      return length > 0 && this.itemCnt === length;
    }
  },
  methods: {
    checkboxClick() {
      if (this.indeterminate || !this.checkboxValue) {
        let items = [...this.dataTableModel];
        this.groupItems.forEach(i => {
          items.push(i);
          if (!i.subCnt) return;

          let subItems = this.subItems[i.id];
          if (!subItems) {
            subItems = [...new Array(i.subCnt)].map((_, idx) => ({
              id: `${i.id}_${idx}`,
              parentId: i.id
            }));
          }

          items = [...items, ...subItems];
        });

        this.$emit("update:dataTableModel", items);
      }

      if (!this.checkboxValue) return;
      this.$emit("update:dataTableModel", []);
    }
  }
};
</script>
