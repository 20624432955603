<template>
  <tbody :style="`height: ${totalHeight}px`">
    <!-- 버츄얼 스크롤 영역 -->
    <tr class="cr-spacer" :style="`height: ${beforeElHeight}px`"></tr>
    <!-- 아이템 목록 -->
    <Tr
      v-bind="$props"
      v-for="item in items"
      :key="item.id"
      :item="item"
      :checked="checkValue(item)"
      @check="check(item)"
    />
    <!-- 아이템 추가 -->
    <AddTr v-bind="$props" />
    <!-- 아이템 종합 표시 -->
    <CombineTr :group="group" :headers="headers" />
    <!-- 아래 여백 -->
    <tr :style="`height: ${TABLE_TD_HEIGHT}px;`"></tr>
    <!-- 버츄얼 아래 영역 -->
    <tr></tr>
  </tbody>
</template>

<script>
import { mapGetters } from "vuex";
import Tr from "./Tr";
import AddTr from "./AddTr";
import CombineTr from "./CombineTr.vue";
import SELECT from "./type/Select.vue";
import TITLE from "./type/title";
import PERSON from "./type/Person.vue";
import STATUS from "./type/Status.vue";
import TIMELINE from "./type/Timeline.vue";
import CREATED_LOG from "./type/CreatedLog.vue";
import TEXT from "./type/Text.vue";
import NUMBER from "./type/Number.vue";
import LINK from "./type/Link.vue";
import CHECK from "./type/Check.vue";

export default {
  components: { Tr, AddTr, CombineTr },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    TABLE_TD_HEIGHT: {
      type: Number,
      default: 48
    },
    beforeElHeight: {
      type: Number,
      default: 0
    },
    totalHeight: {
      type: Number,
      default: 0
    },
    scrollTop: {
      type: Number,
      default: 0
    },
    dragItemId: {
      type: String,
      default: ""
    },
    dataTableModel: {
      type: Array,
      default: () => []
    },
    components: {
      type: Object,
      default: () => ({
        SELECT,
        TITLE,
        PERSON,
        STATUS,
        TIMELINE,
        CREATED_LOG,
        TEXT,
        NUMBER,
        LINK,
        CHECK
      })
    }
  },
  computed: {
    ...mapGetters("todoItem", ["subItems"]),
    checkValue: vue => item => {
      return vue.dataTableModel.findIndex(i => item.id == i?.id) != -1;
    }
  },
  methods: {
    check(item) {
      // 체크 해제
      if (this.checkValue(item)) {
        let filtered = this.dataTableModel.filter(({ id }) => id != item.id);
        if (item.subCnt > 0) {
          filtered = filtered.filter(({ parentId }) => parentId != item.id);
        }

        this.$emit("update:dataTableModel", filtered);
        return;
      }

      // 체크
      let items = [...this.dataTableModel];
      let subItems = this.subItems[item.id];
      if (!subItems) {
        subItems = [...new Array(item.subCnt)].map((_, i) => ({
          id: `${item.id}_${i}`,
          parentId: item.id
        }));
      }
      if (item.subCnt > 0) items = [...items, ...subItems];
      this.$emit("update:dataTableModel", [...items, item]);
    }
  }
};
</script>
