<template>
  <div class="cr-todo-additional-btn">
    <v-btn
      icon
      class="cr-tooltip cr-menu-close-prevent"
      @click="openMenu"
      @mouseover="
        event => SET_TOOLTIP({ msg: '컬럼 추가', position: 'top', event })
      "
      @mouseout="HIDE_TOOLTIP"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-additional-btn {
  min-width: 65px;
  padding-left: 3px;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" })
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("todoMenu", ["setMenu"]),
    openMenu(e) {
      const { top, left, height, width } = e.target.getBoundingClientRect();
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: listWidth } = document.getElementById("todoList");
      const appbarHeight = 64;
      const toolbarHeight = 66;
      const naviWidth = rootWidth - listWidth;

      this.setMenu({
        type: "header",
        itemId: "header" + this.group.id,
        groupId: this.group.id,
        headerValue: this.header.value,
        subType: "add",
        top: top + height - appbarHeight - toolbarHeight,
        left: left - naviWidth + this.sidePanelWidth,
        targetWidth: width,
        targetHeight: height,
        isDetail: this.isDetail
      });
    }
  }
};
</script>
